<script setup>
import { ref } from "vue";
import logo from "../assets/Frame 2.svg";

const year = ref(new Date().getFullYear());
</script>
<template>
  <section class="flex w-full justify-center bg-black">
    <div
      class="content-containter flex w-4/5 items-center justify-between text-sm text-white"
    >
      <p>Copyright © {{ year }} All rights reserved</p>
      <img :src="logo" alt="logo ludou.nl" />

      <a
        href="https://www.instagram.com/ludouvandermeulen/"
        class="flex items-center gap-3"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9259 8.24683C10.4691 8.24683 7.60495 11.0616 7.60495 14.5678C7.60495 18.074 10.4198 20.8888 13.9259 20.8888C17.4321 20.8888 20.2469 18.0246 20.2469 14.5678C20.2469 11.111 17.3827 8.24683 13.9259 8.24683ZM13.9259 18.6172C11.7037 18.6172 9.87656 16.79 9.87656 14.5678C9.87656 12.3456 11.7037 10.5184 13.9259 10.5184C16.1482 10.5184 17.9753 12.3456 17.9753 14.5678C17.9753 16.79 16.1482 18.6172 13.9259 18.6172Z"
            fill="white"
          />
          <path
            d="M20.4938 9.53095C21.2847 9.53095 21.9259 8.88977 21.9259 8.09885C21.9259 7.30792 21.2847 6.66675 20.4938 6.66675C19.7029 6.66675 19.0617 7.30792 19.0617 8.09885C19.0617 8.88977 19.7029 9.53095 20.4938 9.53095Z"
            fill="white"
          />
          <path
            d="M24.1975 4.39505C22.9136 3.06172 21.0864 2.37036 19.0123 2.37036H8.83951C4.54321 2.37036 1.67902 5.23456 1.67902 9.53085V19.6543C1.67902 21.7778 2.37037 23.6049 3.75309 24.9383C5.08642 26.2222 6.8642 26.8642 8.88889 26.8642H18.963C21.0864 26.8642 22.8642 26.1728 24.1482 24.9383C25.4815 23.6543 26.1728 21.8272 26.1728 19.7037V9.53085C26.1728 7.45678 25.4815 5.679 24.1975 4.39505ZM24 19.7037C24 21.2346 23.4568 22.4691 22.5679 23.3086C21.679 24.1481 20.4444 24.5926 18.963 24.5926H8.88889C7.40741 24.5926 6.17284 24.1481 5.28395 23.3086C4.39507 22.4197 3.95062 21.1852 3.95062 19.6543V9.53085C3.95062 8.04937 4.39507 6.81481 5.28395 5.92592C6.12346 5.08641 7.40741 4.64197 8.88889 4.64197H19.0617C20.5432 4.64197 21.7778 5.08641 22.6667 5.9753C23.5062 6.86419 24 8.09876 24 9.53085V19.7037Z"
            fill="white"
          />
        </svg>

        <p>@ludouvandermeulen</p>
      </a>
    </div>
  </section>
</template>
