<script setup>
const scroller = (x, y) => {
  scrollTo({
    top: y,
    left: x,
    behavior: "smooth",
  });
};
</script>
<template>
  <section
    class="background relative z-10 flex h-[800px] items-center justify-center bg-black bg-[url(https://a.storyblok.com/f/211339/2511x3092/c9161cd420/ludou.jpg)] after:absolute after:left-0 after:top-0 after:z-[-1] after:h-full after:w-full after:bg-[rgba(0,0,0,0.4)] after:content-['*']"
  >
    <div class="container flex w-4/5 flex-col gap-10">
      <h1
        class="bg-text-col text-[50px] font-extrabold text-transparent md:text-[84px]"
      >
        Hallo, <br />Ik ben ludou
      </h1>
      <p class="text-white">In mijn vrije tijd teken en maak ik foto's</p>
      <div class="">
        <button @click="scroller(0, 750)" class="button">
          Neem een kijkje
        </button>
      </div>
    </div>
  </section>
</template>
<style>
.background {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.bg-text-col {
  background: -webkit-linear-gradient(#ff9696, #ffe89e);
  -webkit-background-clip: text;
}
.button {
  position: relative;
  z-index: 1;
  min-width: 160px;
  height: 46px;
  line-height: 42px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  padding: 0 30px;
  text-align: center;
  text-transform: capitalize;
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #fc6060;
  border-radius: 60px;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}
.button:hover {
  font-weight: 500;
  -webkit-box-shadow: 0 2px 40px 8px rgba(15, 15, 15, 0.15);
  box-shadow: 0 2px 40px 8px rgba(15, 15, 15, 0.15);
  background-color: #fc6060;
  color: #ffffff;
}
</style>
