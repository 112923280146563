import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/main.css";
import { StoryblokVue, apiPlugin } from "@storyblok/vue";
import "./assets/main.css";

import Portfolio from "./components/Portfolio.vue";

const app = createApp(App);

app.component("Portfolio", Portfolio);

app.use(router);

app.use(StoryblokVue, {
  accessToken: "eLO45aqbVTTDc1FVQSjCvgtt",
  bridge: true, // enable the bridge
  // bridge: process.env.NODE_ENV !== "production", // optimizes by excluding the bridge on production

  use: [apiPlugin],
});

app.mount("#app");
