<script setup>
import { useStoryblokApi } from "@storyblok/vue";
import { ref } from "vue";

const storyblokApi = useStoryblokApi();

const { data } = await storyblokApi.get("cdn/stories/", {
  version: "published",
  starts_with: "foto-s/",
});

const paginas = ref(data.stories);

const tagList = paginas.value.reduce((acc, curr) => {
  curr.tag_list.forEach((tag) => {
    if (!acc.includes(tag)) {
      acc.push(tag);
    }
  });
  return acc;
}, []);

const filters = ref(tagList);

// filter active
const clickStatus = ref(-1);

const filterPages = () => {
  paginas.value = data.stories.filter((page) => {
    return (
      clickStatus.value === -1 || page.tag_list.includes(clickStatus.value)
    );
    // hond === hond
  });
};

const filterActive = (filter) => {
  clickStatus.value = filter;
  filterPages();
};

const fullScreen = ref(false);
const fullScreenImg = ref();
const fullScreenAlt = ref();
const timeData = ref();

const fullScreenHandler = (src, alt, time) => {
  fullScreen.value = !fullScreen.value;
  fullScreenImg.value = src;
  fullScreenAlt.value = alt;
  const timeFormatter = new Date(time).toLocaleDateString();
  timeData.value = timeFormatter;
};
</script>

<template>
  <section
    v-if="fullScreen"
    class="flex h-screen w-full flex-col items-center justify-center gap-3 p-3 pb-32"
  >
    <div class="close mr-20 flex w-full justify-end">
      <svg
        width="512"
        height="512"
        viewBox="0 0 512 512"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="h-[50px] w-[50px] duration-300 ease-linear hover:rotate-180"
        @click="fullScreen = false"
      >
        <path
          d="M256 33C132.3 33 32 133.3 32 257C32 380.7 132.3 481 256 481C379.7 481 480 380.7 480 257C480 133.3 379.7 33 256 33ZM364.3 332.5C365.8 334 366.6 336 366.6 338.1C366.6 340.2 365.8 342.3 364.3 343.7L342.7 365.4C341.1 367 339.1 367.7 337.1 367.7C335.1 367.7 333 366.9 331.5 365.4L256 289.8L180.6 365.5C179.1 367.1 177 367.8 175 367.8C173 367.8 170.9 367 169.4 365.5L147.8 343.8C146.3 342.3 145.5 340.3 145.5 338.2C145.5 336.1 146.3 334 147.8 332.6L223.5 256.6L147.6 181.6C144.5 178.5 144.5 173.4 147.6 170.3L169.2 148.6C170.7 147.1 172.7 146.3 174.8 146.3C176.9 146.3 178.9 147.1 180.4 148.6L256.1 223.3L331.8 148.6C333.3 147.1 335.3 146.3 337.4 146.3C339.5 146.3 341.5 147.1 343 148.6L364.6 170.3C367.7 173.4 367.7 178.5 364.6 181.6L288.7 256.6L364.3 332.5Z"
          fill="#fc6060"
        />
      </svg>
    </div>
    <div
      class="flex h-4/5 w-full flex-col items-center justify-center gap-12 overflow-hidden md:flex-row"
    >
      <div class="flex flex-col">
        <h1>{{ fullScreenAlt }}</h1>

        <p>{{ timeData }}</p>
      </div>

      <img
        class="h-1/2 w-auto md:h-2/3 lg:h-5/6"
        :src="fullScreenImg"
        :alt="fullScreenAlt"
      />
    </div>
  </section>
  <section
    class="flex flex-col items-center justify-center gap-5 py-5 pb-24"
    v-else-if="data"
  >
    <div class="filter-bar">
      <ul class="flex gap-10">
        <li
          class="cursor-pointer border-roodTheme duration-300 ease-linear"
          :class="{ 'border-b-4': clickStatus === -1 }"
          @click="filterActive(-1)"
        >
          <h3>Alles</h3>
        </li>
        <li
          v-for="filter in filters"
          class="hob cursor-pointer border-roodTheme duration-300 ease-linear"
          :class="{ 'border-b-4': filter === clickStatus }"
          @click="filterActive(filter)"
        >
          <h3>{{ filter }}</h3>
        </li>
      </ul>
    </div>
    <div class="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
      <div class="-m-1 flex min-h-[356px] flex-wrap md:-m-2">
        <div
          class="flex w-1/2 flex-wrap md:w-1/4"
          v-for="pagina in paginas"
          :key="pagina.slug"
        >
          <div
            class="w-full p-1 md:p-2"
            @click="
              fullScreenHandler(
                pagina.content.Foto.filename,
                pagina.content.Title,
                pagina.first_published_at
              )
            "
          >
            <img
              v-if="pagina.content.Foto.filename"
              :alt="pagina.content.Title"
              loading="lazy"
              class="p-hover h-full w-full rounded-lg object-cover object-center duration-300 ease-linear hover:scale-105"
              :src="pagina.content.Foto.filename"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped></style>
